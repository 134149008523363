<template>
  <section class="recovery">
    <div class="content">
      <RecoveryPasswordForm is-mobile v-bind="attrs" />
    </div>
  </section>
</template>

<script setup lang="ts">
import RecoveryPasswordForm from './RecoveryPasswordForm.vue'

const attrs = useAttrs()
</script>
<style scoped>
.recovery {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex-grow: 1;
  margin-top: var(--spacing-200);
}
</style>
